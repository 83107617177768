'use client';

/* eslint-disable react/jsx-max-depth */
import Link from 'next/link';

import { Button, buttonVariants } from '@repo/ui/primitives/button';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@repo/ui/primitives/card';
import { LogoIcon } from '@repo/ui/primitives/logo-icon';

// Next.js wants this to be a default export rather than a named export
// eslint-disable-next-line import/no-default-export
export default function FullPageError() {
  return (
    <div className="grid h-full place-items-center px-8">
      <Card className="w-full max-w-sm border-none shadow-none">
        <CardHeader className="w-full items-center justify-center gap-5">
          <LogoIcon />
          <CardTitle className="text-3xl">Something went wrong.</CardTitle>
        </CardHeader>
        <CardContent className="grid gap-4 text-center leading-6">
          <p className="leading-relaxed">
            We encountered an unexpected error. But don&apos;t worry, we&apos;re
            on it!
          </p>
          <div className="mt-4 flex flex-row items-center justify-center gap-2">
            <Link
              href="/"
              className={buttonVariants({
                variant: 'default',
              })}
            >
              Back to home
            </Link>
            <Button variant="outline">
              <Link href="mailto:engineering@getclarify.ai">Get help</Link>
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
