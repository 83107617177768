export function LogoIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      aria-hidden="true"
      viewBox="0 0 24 24"
      width="24"
      {...props}
    >
      <path d="M18.5969 6.40264C19.0966 5.85711 19.9159 5.85196 20.3353 6.47289C20.7444 7.07862 21.0857 7.73766 21.3507 8.43615C21.7794 9.56603 22 10.777 22 12C22 13.223 21.7794 14.434 21.3507 15.5638C21.0857 16.2623 20.7444 16.9214 20.3353 17.5271C19.9159 18.148 19.0966 18.1429 18.5969 17.5974L14.3748 12.9878C13.8751 12.4422 13.8751 11.5578 14.3748 11.0122L18.5969 6.40264Z" fill="var(--brand)"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.12031 3.33452C8.67363 2.45931 10.4349 1.99908 12.2274 2C14.02 2.00092 15.7807 2.46295 17.3331 3.33975C17.9708 3.69993 18.1893 4.49766 17.8211 5.12152C17.453 5.74538 16.6375 5.95913 15.9998 5.59894C14.8524 4.95088 13.551 4.60937 12.226 4.6087C10.9011 4.60802 9.59931 4.94819 8.45121 5.59508C7.3031 6.24197 6.34907 7.17283 5.68481 8.2943C5.02055 9.41576 4.66942 10.6884 4.66665 11.9845C4.66387 13.2806 5.00955 14.5547 5.669 15.6789C6.32845 16.8031 7.27849 17.7378 8.42382 18.3894C9.56914 19.041 10.8695 19.3865 12.1944 19.3913C13.5193 19.396 14.8222 19.0598 15.9724 18.4165C16.6116 18.0589 17.4261 18.276 17.7916 18.9014C18.1572 19.5267 17.9352 20.3236 17.296 20.6811C15.7399 21.5515 13.9771 22.0064 12.1846 21.9999C10.3921 21.9935 8.63281 21.5261 7.08326 20.6445C5.5337 19.7629 4.24835 18.4983 3.35615 16.9773C2.46395 15.4564 1.99627 13.7326 2.00002 11.9791C2.00378 10.2255 2.47883 8.50368 3.37754 6.9864C4.27624 5.46913 5.567 4.20972 7.12031 3.33452Z" fill="var(--brand)"/>
    </svg>
  );
}

