import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@repo/ui/lib/utils';
import { SpinnerClarifyIcon } from '../icons';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md font-medium transition-all focus-visible:outline-none focus-visible:ring-0 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'font-semibold bg-brand-500 shadow-button-primary text-white hover:bg-brand-600 hover:shadow-button-primary-hover [&>svg]:text-white [&>svg]:dark:text-white',
        destructive:
          'bg-red-500 text-white shadow-button-destructive hover:shadow-button-destructive-hover hover:bg-red-600 [&>svg]:text-white [&>svg]:dark:text-white',
        outline:
          'shadow-button bg-white hover:shadow-button-hover dark:bg-gray-850 dark:hover:bg-gray-800',
        secondary:
        'bg-gray-150 text-gray-800 hover:bg-gray-200 dark:bg-gray-800 dark:text-gray-200 dark:hover:bg-gray-850',
        ghost: 'text-gray-700 hover:bg-gray-100 hover:text-gray-800 dark:text-gray-200 dark:hover:bg-gray-800 dark:hover:text-gray-100',
        'ghost-layer':
          'hover:bg-gray-100 hover:text-gray-950 dark:hover:bg-gray-800 dark:hover:text-gray-50',
        link: 'text-brand-500 hover:text-brand-600 dark:text-brand-400 dark:hover:text-brand-500',
        'link-destructive': 'text-red-500 hover:text-red-600',
      },
      size: {
        default: 'h-7 px-2.5',
        text: 'h-8 px-0',
        sm: 'h-7 rounded-md px-2.5',
        lg: 'h-9 rounded-lg px-4',
        icon: 'h-5 w-5',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, variant, size, asChild = false, children, loading, ...props },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button';

    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
        disabled={loading || props.disabled}
      >
        {asChild ? (
          children
        ) : (
          <>
            {loading && <SpinnerClarifyIcon className="mr-2 h-4 w-4 animate-spin" />}
            {children}
          </>
        )}
      </Comp>
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
